import { useEffect, useMemo, useState } from 'react';
import builderConfig from '../../builder-config';
import { getBuilderAPI } from '../api/builderContent';
import { Builder } from '@builder.io/react';

const apiKey = builderConfig?.builderAPIKey;

export const builderModels = {
    page: 'page',
    landingPage: 'landing-page',
    author: 'author',
    symbol: 'symbol',
    promotionsBanners: 'promotions-banners',
};

const routeSubDirectories = {
    landingPage: 'lp',
    authors: 'authors',
    editSymbol: 'edit-symbol',
};

export const getRouteInfoFromPath = (path) => {
    // Split the pathname into parts, removing leading/trailing slashes
    const pathParts = path?.split('/').filter(Boolean);

    return {
        subDirectory: pathParts.length >= 1 ? pathParts[0] : null, // Return the second subdirectory (if it exists)
        slug: '/' + pathParts[pathParts.length - 1], // Return the last slug
    };
};

export const getBuilderModelInfo = (subDirectory) => {
    switch (subDirectory) {
        //add your subdirectory and model here
        case routeSubDirectories.landingPage:
            return {
                model: builderModels.landingPage,
                isShowBuilderPage: builderConfig.showBuilderLandingPages,
            };
        case routeSubDirectories.authors:
            return {
                model: builderModels.author,
                isShowBuilderPage: builderConfig.showBuilderAuthorPages,
            };
        case routeSubDirectories.editSymbol:
            return {
                model: builderModels.symbol,
                isShowBuilderPage: builderConfig.showBuilderSymbols,
            };
        default:
            return {
                model: builderModels.page,
                isShowBuilderPage: builderConfig.showBuilderPages,
            };
    }
};

export const useBuilderPage = () => {
    const [content, setContent] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [origin, setOrigin] = useState('');
    const [isContentLoading, setIsContentLoading] = useState(true);

    const pathName = typeof window !== 'undefined' ? window?.location?.pathname : '';
    const subDirectory = getRouteInfoFromPath(pathName)?.subDirectory;
    const urlPath = getRouteInfoFromPath(pathName)?.slug;

    const model = getBuilderModelInfo(subDirectory)?.model;
    const isShowBuilderPage = getBuilderModelInfo(subDirectory)?.isShowBuilderPage;

    useEffect(() => {
        if (!isShowBuilderPage) {
            setContent(null);
            return setNotFound(true);
        }

        // Do not render the symbol page if the Builder is not editing or not previewing
        if (model === builderModels.symbol && !Builder.isEditing && !Builder.isPreviewing) {
            setContent(null);
            return setNotFound(true);
        }

        async function fetchContent() {
            try {
                const fetchContent = await fetch(getBuilderAPI(model, apiKey, urlPath).fetchUrl);
                const content = await fetchContent.json();
                const result = content?.results[0];

                // if the page title is found,
                // set the document title
                if (result.data?.title) {
                    document.title = result?.data?.title;
                }

                // If page has blocks, resolve any Symbol components

                if (result?.data?.blocks) {
                    const resolvedBlocks = await Promise.all(
                        result?.data.blocks.map(async (block) => {
                            // Check if the block is a Symbol component
                            if (block.component?.name === 'Symbol' && block.component.options.symbol?.entry) {
                                const symbolId = block.component.options.symbol.entry;

                                // Fetch the Symbol content by its entry ID
                                const symbolResponse = await fetch(
                                    `https://cdn.builder.io/api/v3/content/symbol/${symbolId}?apiKey=${apiKey}&preview=true&cachebust=true`,
                                );
                                const symbolData = await symbolResponse.json();

                                // Replace the Symbol block with its resolved content
                                return symbolData?.data?.blocks?.[0] || block;
                            }
                            return block; // Return the original block if it's not a Symbol
                        }),
                    );

                    // Update the page content with resolved blocks
                    result.data.blocks = resolvedBlocks;
                    setContent(result); // Set the resolved page content
                    setIsContentLoading(false); // Mark as content loaded
                } else {
                    setNotFound(true); // Mark as not found if no blocks exist
                    setIsContentLoading(false); // Mark as content loaded
                }
            } catch (error) {
                console.error('Error:', error);
                setNotFound(true);
                setIsContentLoading(false); // Mark as content loaded
            }
        }

        fetchContent();
    }, [pathName, model, subDirectory]);

    //this is to get the origin of the site
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setOrigin(window.location.origin);
        }
    }, []);

    return useMemo(() => {
        return {
            urlPath,
            slug: getRouteInfoFromPath(pathName)?.slug,
            model: getBuilderModelInfo(subDirectory, pathName)?.model,
            content,
            notFound,
            isShowBuilderPage,
            origin,
            title: content?.data?.title, //make sure to add this field on a builder model
            description: content?.data?.description, //make sure to add this field on a builder model
            isContentLoading,
        };
    }, [content, pathName, subDirectory, urlPath, notFound, isShowBuilderPage, isContentLoading]);
};
